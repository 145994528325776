.gimmesong-primary-font {
    font-family: "Mexa";
}

.gimmesong-secondary-font {
    font-family: "LINE Seed Sans TH";
}

.gimmesong-bg {
    background-color: #F9F9F9;
}

.text-xxs {
    font-size: 0.65rem;
    line-height: 1rem;
}

.animate-pause {
    animation-play-state: paused;
}