@tailwind base;
@tailwind components;
@tailwind utilities;

*,
*::before,
*::after {
    box-sizing: border-box;
}

* {
    margin: 0;
}

html,
body,
#root,
#__next {
    height: 100%;
    background-color: #F9F9F9;

}

body {
    line-height: 1.5;
    color: white;
    font-family: "LINE Seed Sans TH", sans-serif;
    -webkit-font-smoothing: antialiased;
}

img,
picture,
video,
canvas,
svg {
    display: block;
    max-width: 100%;
}

input,
button,
textarea,
select {
    font: inherit;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
    overflow-wrap: break-word;
}

#root,
#__next {
    isolation: isolate;
}

a,
a:hover {
    text-decoration: none;
}

@font-face {
    font-family: "Mexa";
    src: url("@assets/fonts/Mexa-Regular.woff2") format("woff2"),
        url("@assets/fonts/Mexa-Regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: "LINE Seed Sans TH";
    src: url("@assets/fonts/LINESeedSansTH_W_Rg.woff2") format("woff2"),
        url("@assets/fonts/LINESeedSansTH_W_Rg.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: fallback;
}

@font-face {
    font-family: "LINE Seed Sans TH";
    src: url("@assets/fonts/LINESeedSansTH_W_Th.woff2") format("woff2"),
        url("@assets/fonts/LINESeedSansTH_W_Rg.woff") format("woff");
    font-weight: 300;
    font-style: normal;
    font-display: fallback;
}